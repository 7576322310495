.App {
  text-align: center;
}
html{
  scroll-behavior: smooth;
}
body {
  background-color: #fff;
  /* background: #eaf7f7 !important; */
}

.btn{
  color: #fff !important;
font-weight: 600 !important;
line-height: 1rem !important;
font-size: 16px !important;
transition: 0.3s all !important;
text-transform: capitalize !important;
font-family: KanitRegular !important;

}
.social_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border: 2px solid #222222;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 6px;
}

.social_icon:hover {
  border-color: rgb(34, 75, 141);
}

.social_icon svg {
  font-size: 18px;
}
.bin1 {
  width: 100%;
  border: 2px solid transparent !important;
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.07);
  color: #fff;
}

.bin1:focus-visible {
  outline: none !important;
  border: none !important;
}

.bin1:hover {
  border: none;
}

.bin1:focus {
  box-shadow: none !important;
  border: 2px solid #222222 !important;
  border-radius: 0px !important;
}

.bin1 .MuiInputBase-root::before,
.bin1 .MuiInputBase-root::after {
  display: none;
}

.bin1 input {
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 500;
  font-family: KanitRegular;
  color: #000 !important;
}

.bin1 input::placeholder {
  color: #0000008f !important;
  font-size: 16px;
}

.bin1 textarea {
  height: 90px !important;
  padding: 10px !important;
  color: #000;
}

.bin1 textarea:focus {
  outline: none;
}

.bin1 textarea::placeholder {
  color: #0000008f;
}

.error {
  margin: 0.5rem 0;
  font-size: 14px !important;
  color: red !important;
  text-align: start;
}

@font-face {
  font-family: 'KanitBold';
  src: url('/src/Assets/font/PlayfairDisplay-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'KanitRegular';
  src: url('/src/Assets/font/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'KanitMedium';
  src: url('/src/Assets/font/PlayfairDisplay-Bold.ttf') format('truetype');
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dividerbox {
  width: 100%;
  height: 0px;
  background-color: #ffffff8a;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 12px;
  background-color: #fff;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px black; 
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  -webkit-box-shadow: inset 0 2px 10px rgb(29, 79, 158); 
}

 .swiper-button-prev:after, .swiper-button-next:after{
 font-size: 30px !important;
 font-weight: bolder;
}
.myBtn {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: rgb(34, 75, 141);
  color: white;
  cursor: pointer;
  padding: 15px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.myBtn svg{
  font-weight: 800;
}

.none {
  display: none;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next{
  left: 0 !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-next{
  right: 0 !important;
}

.myBtn:hover {
  background-color: rgb(42, 72, 121);
}
.swiper-pagination-bullet{
  background: #fff !important;
  opacity: 0.4 !important;
}
.swiper-pagination-bullet-active{
  background:#007aff !important;
  opacity: 1 !important;
}